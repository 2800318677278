.DraftEditor-root {
    min-height: inherit;
    max-height: inherit;
}

.DraftEditor-editorContainer {
    min-height: inherit;
    max-height: inherit;
}

.public-DraftEditorPlaceholder-root {
    color: #999999;
    font-size: inherit;
    line-height: inherit;
}

.public-DraftEditor-content {
    min-height: inherit;
    max-height: inherit;
    overflow-y: scroll;
}
