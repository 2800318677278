@font-face {
  font-family: "MuseoSansCyrl";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src:
    url("/museo-sans-cyrl-100.woff2") format("woff2"),
    url("/museo-sans-cyrl-100.woff") format("woff");
}

@font-face {
  font-family: "MuseoSansCyrl";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    url("/museo-sans-cyrl-300.woff2") format("woff2"),
    url("/museo-sans-cyrl-300.woff") format("woff");
}

@font-face {
  font-family: "MuseoSansCyrl";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src:
    url("/museo-sans-cyrl-700.woff2") format("woff2"),
    url("/museo-sans-cyrl-700.woff") format("woff");
}

$font-family: "MuseoSansCyrl", sans-serif;
