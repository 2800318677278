@import "global/styles";

html {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  font-family: $font-family;
  box-sizing: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

legend {
  padding: 0;
}

/* IE10+ */
::-ms-clear {
  display: none;
}

/* Chrome */
::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
}

@media print {
  .MuiDrawer-root.MuiDrawer-docked {
    display: none;
  }
}
